import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const API_PATH = "https://samattrade.sk/mail/formulare.php";

const Formular = (props) => {
  const captchaRef = useRef(null);

  const [isEmailValid, setEmailValid] = useState(null);
  const [isICOValid, setICOValid] = useState(null);
  const [isDICValid, setDICValid] = useState(null);
  const [isAddressValid, setAddressValid] = useState(null);
  const [isTownValid, setTownValid] = useState(null);
  const [isZIPValid, setZIPValid] = useState(null);

  const [emailAdr, setEmailAdr] = useState("");
  const [name, setName] = useState("");
  const [organization, setOrganization] = useState("");
  const [ICO, setICO] = useState("0");
  const [DIC, setDIC] = useState("0");
  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [phone, setPhone] = useState("");
  const [ZIP, setZIP] = useState("");
  const [prsty55, setPrsty55] = useState("0");
  const [prsty30, setPrsty30] = useState("0");
  const [sprava, setSprava] = useState("");
  const [doprava, setDoprava] = useState(
    "Slovenskou poštou(express kurier)- dobierka"
  );
  const [btnEnabled, setBtnEnabled] = useState("disabled");
  //const [captcha, setCaptcha] = useState("");

  const [sendResult, setSendResult] = useState();

  function onChange(value) {
    //console.log(value);
    //setCaptcha(captchaRef.current.getValue());
  }
  const validateEmail = (e) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmailAdr(e.target.value);
    if (emailRegex.test(e.target.value)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const validateOrg = (name, ICO, DIC) => {
    // ICO ma 6 alebo 8 cislic
    // DIC 9-10 cislic
    if (name === "") {
      setICOValid(false);
      setDICValid(false);
      return true;
    }
    if (!(ICO.trim().length === 6 || ICO.trim().length === 8)) {
      setICOValid(false);
      return false;
    }
    if (DIC.trim().length < 9 && DIC.trim().length > 10) {
      setDICValid(false);
      return false;
    }
    setICOValid(true);
    setDICValid(true);
    return true;
  };

  const validateAddress = (addr, town, zip) => {
    const addrArray = addr.trim().split(" ");
    if (isNaN(Number(addrArray.pop())) || addr.length < 5) {
      setAddressValid(false);
      return false;
    } else setAddressValid(true);
    if (town.length < 3) {
      setTownValid(false);
      return false;
    } else setTownValid(true);
    if (zip.replaceAll(" ", "").length !== 5) {
      setZIPValid(false);
      return false;
    } else setZIPValid(true);
    return true;
  };

  const validateForm = () => {
    const isOrgValid = validateOrg(organization, ICO, DIC);
    const isCountValid = prsty55 > 0 || prsty30 > 0;
    const isAddressValid = validateAddress(address, town, ZIP);
    if (isOrgValid && isCountValid && isAddressValid && isEmailValid) {
      setBtnEnabled("");
    } else setBtnEnabled("disabled");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const isOrgValid = validateOrg(organization, ICO, DIC);
    const isCountValid = prsty55 > 0 || prsty30 > 0;
    const isAddressValid = validateAddress(address, town, ZIP);

    const data = {
      email: emailAdr,
      kusy: prsty55,
      kusy1: prsty30,
      meno: name,
      org: organization,
      ico: ICO,
      dic: DIC,
      ulica: address,
      mesto: town,
      psc: ZIP,
      tel: phone,
      doprava: doprava,
      message: sprava,
      recaptcha: captchaRef.current.getValue(),
    };
    if (
      isOrgValid &&
      isCountValid &&
      isAddressValid &&
      isEmailValid &&
      captchaRef.current.getValue() !== ""
    ) {
      props.handleSent(true);
      axios({
        method: "post",
        url: `${API_PATH}`,
        headers: { "content-type": "application/json" },
        data: data,
        timeout: 4000,
      })
        .then((result) => {
          setSendResult(result);
          //console.log(sendResult);
          props.handleSent(true);
        })
        .catch((error) => {
          setSendResult(error);
          console.error(sendResult);
        });
    }
    captchaRef.current.reset();
  };

  return (
    <div className="bg-light p-t-10 p-b-20">
      <Container className="m-t-10">
        <h3 className="m-t-10 m-b-20">Objednávací formulár</h3>
        <Row>
          <Form>
            <FormGroup row>
              <Label for="email" md={4}>
                Email
              </Label>
              <Col md={8}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Váš e-mail"
                  valid={isEmailValid}
                  invalid={isEmailValid === false}
                  onChange={(e) => {
                    validateEmail(e);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="Počet kusov 55°Sh" md={4}>
                Počet kusov 55°Sh
              </Label>
              <Col md={8}>
                <Input
                  type="number"
                  name="kusy"
                  id="kusy"
                  placeholder="0"
                  valid={Number(prsty55) >= 1}
                  onChange={(e) => {
                    if (e.target.value < 0) e.target.value *= -1;
                    setPrsty55(e.target.value);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="Počet kusov 30°Sh" md={4}>
                Počet kusov 35°Sh
              </Label>
              <Col md={8}>
                <Input
                  type="number"
                  name="kusy1"
                  id="kusy1"
                  placeholder="0"
                  valid={Number(prsty30) >= 1}
                  onChange={(e) => {
                    if (e.target.value < 0) e.target.value *= -1;
                    setPrsty30(e.target.value);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="Meno a priezvisko" md={4}>
                Meno a priezvisko
              </Label>
              <Col md={8}>
                <Input
                  name="meno"
                  id="meno"
                  placeholder="Meno a priezvisko"
                  valid={name.trim().length > 2}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="Organizácia" md={4}>
                Organizácia
              </Label>
              <Col md={8}>
                <Input
                  name="org"
                  id="org"
                  placeholder="Organizácia"
                  valid={organization.length > 2}
                  onChange={(e) => {
                    setOrganization(e.target.value);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="ICO" md={4}>
                IČO
              </Label>
              <Col md={8}>
                <Input
                  name="ico"
                  id="ico"
                  placeholder="IČO"
                  valid={isICOValid}
                  onChange={(e) => {
                    const val = e.target.value;
                    setICO(val);
                    if (val.trim().length === 6 || val.trim().length === 8)
                      setICOValid(true);
                    else setICOValid(false);
                    //validateOrg(organization, val, DIC);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="DIČ DPH" md={4}>
                DIČ DPH
              </Label>
              <Col md={8}>
                <Input
                  name="dic"
                  id="dic"
                  placeholder="DIČ DPH"
                  valid={isDICValid}
                  onChange={(e) => {
                    const val = e.target.value;
                    setDIC(val);
                    if (val.trim().length === 9 || val.trim().length === 10)
                      setDICValid(true);
                    else setDICValid(false);
                    //validateOrg(organization, ICO, val);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="Adresa" md={4}>
                Ulica/Obec a číslo
              </Label>
              <Col md={8}>
                <Input
                  name="ulica"
                  id="ulica"
                  placeholder="Ulica 123"
                  valid={isAddressValid}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="Mesto" md={4}>
                Mesto
              </Label>
              <Col md={8}>
                <Input
                  name="mesto"
                  id="mesto"
                  placeholder="Mesto"
                  valid={isTownValid}
                  onChange={(e) => {
                    setTown(e.target.value);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="PSČ" md={4}>
                PSČ
              </Label>
              <Col md={8}>
                <Input
                  name="psc"
                  id="psc"
                  placeholder="PSČ"
                  valid={isZIPValid}
                  onChange={(e) => {
                    setZIP(e.target.value);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="Telefon" md={4}>
                Telefónne číslo
              </Label>
              <Col md={8}>
                <Input
                  name="tel"
                  id="tel"
                  placeholder="+421 xxx xxx xxx"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  onBlur={validateForm}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="Doprava" md={4}>
                Doprava
              </Label>
              <Col md={8}>
                <Input
                  type="select"
                  name="doprava"
                  onBlur={(e) => {
                    setDoprava(e.target.value);
                    validateForm();
                  }}
                >
                  <option>Slovenskou poštou(express kurier)- dobierka</option>
                  <option>Osobný odber - platba vopred - predfaktúra</option>
                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="Dodatočné informácie" md={4}>
                Dodatočné informácie
              </Label>
              <Col md={8}>
                <Input
                  type="textarea"
                  name="message"
                  id="Dodatočné informácie"
                  onBlur={(e) => {
                    setSprava(e.target.value);
                    validateForm();
                  }}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={{ size: 10, offset: 2 }}>
                <ReCAPTCHA
                  sitekey="6Lf0JwIoAAAAACPuQlL0QQJqhPpJHTznwngYNUqD"
                  onChange={() => {
                    onChange();
                    validateForm();
                  }}
                  ref={captchaRef}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col>
                Odoslaním súhlasím so spracovaním{" "}
                <a
                  href="Zasady_ochrany_osobnych_udajov_spolocnosti.pdf"
                  target="_blank"
                  title="Dňa 25. 5. 2018 vstúpilo do platnosti Nariadenie (EÚ) (2016/679 GDPR), o spracovaní osobných údajov fyzických osôb"
                >
                  osobných údajov
                </a>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={{ size: 10, offset: 2 }}>
                <Button
                  onClick={onSubmit}
                  className={`btn m-t-20 btn-arrow ${btnEnabled}`}
                >
                  <span>
                    {" "}
                    Odoslať <i className="ti-email"></i>
                  </span>
                </Button>
              </Col>
            </FormGroup>
          </Form>
        </Row>
      </Container>
    </div>
  );
};

export default Formular;
