/* eslint-disable */
import { useState } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Collapse,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";

import gule from "../../../assets/images/portfolio/gule01_md.jpg";
import prsty from "../../../assets/images/portfolio/prst.jpg";
import prst_koty from "../../../assets/images/portfolio/prst_koty.jpg";
import prisavky1 from "../../../assets/images/portfolio/prisavky01_md.jpg";
import okruzky from "../../../assets/images/portfolio/okruzky_md.jpg";
import tesnenia from "../../../assets/images/portfolio/tesnenia_md.jpg";
import prisavky2 from "../../../assets/images/portfolio/prisavky_md.jpg";

import guleLg from "../../../assets/images/portfolio/gule01_lg.jpg";
import prisavky1Lg from "../../../assets/images/portfolio/prisavky01_lg.jpg";
import okruzkyLg from "../../../assets/images/portfolio/okruzky_lg.jpg";
import tesneniaLg from "../../../assets/images/portfolio/tesnenia_lg.jpg";
import prisavky2Lg from "../../../assets/images/portfolio/prisavky_lg.jpg";

const PortfolioComponent = (props) => {
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal6, setModal6] = useState(false);

  const toggle1 = () => {
    setModal1(!modal1);
  };
  const toggle2 = () => {
    setModal2(!modal2);
  };
  const toggle3 = () => {
    setModal3(!modal3);
  };
  const toggle4 = () => {
    setModal4(!modal4);
  };
  const toggle5 = () => {
    setModal5(!modal5);
  };
  const toggle6 = () => {
    setModal6(!modal6);
  };

  const [isOpenPrsty, setIsOpenPrsty] = useState(false);

  const togglePrsty = () => setIsOpenPrsty(!isOpenPrsty);

  return (
    <div>
      <div className="spacer">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h2 className="title">Examples of our rubber products</h2>
            </Col>
          </Row>
          <Row className="m-t-40">
            <Col md="4" onClick={togglePrsty}>
              <Card className="card-shadow ">
                <div className="img-ho" onClick={toggle1.bind(null)}>
                  <img
                    className="card-img-top "
                    src={prsty}
                    alt="sklbacie prsty"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">Plucking fingers</h5>
                  <p className="m-b-0 font-14">Designed for cleaning poultry</p>
                </CardBody>
              </Card>
              <Modal
                size="xl"
                isOpen={modal1}
                toggle={toggle1.bind(null)}
                className={props.className}
              >
                <ModalHeader toggle={toggle1.bind(null)}>
                  Plucking fingers
                </ModalHeader>
                <ModalBody>
                  <img src={prsty} style={{ float: "left", margin: "5px" }} />
                  <p>
                    <h5>Description:</h5>
                    The plucking finger is made of food-grade rubber and is
                    designed for the mechanical removal of feathers from
                    poultry. <br />
                    The plucking fingers with a hardness of 55°Sh are
                    recommended for plucking hens, but you can also achieve
                    excellent results when plucking turkeys. <br />
                    For plucking smaller feathers (e.g. quail) we recommend the
                    softer plucking fingers with a hardness of 30°Sh.
                  </p>
                  <p>
                    <h5>Nominal dimensions and technical data:</h5>
                    diameter d = 23 mm
                    <br /> length l = 90 mm
                    <br /> hardness = 55°Sh a 30°Sh <br />
                    <br />
                    <h5>Material:</h5>
                    Food-grade rubber <br />
                    <br />
                    <h5>Application:</h5>
                    poultry industry, food industry <br />
                    <br />
                    <h5>Mounting:</h5>
                    aluminium casting hole d = 18.50 mm, thickness at the fixing
                    point approx. 4 mm <br />
                    <br />
                    Functionality verified by operational tests in Slovak
                    poultry plants
                  </p>
                  <img
                    src={prst_koty}
                    style={{ float: "left", margin: "5px", width: "100%" }}
                  />
                </ModalBody>
              </Modal>
            </Col>
            <Col md="4">
              <Card className="card-shadow">
                <div className="img-ho" onClick={toggle2.bind(null)}>
                  <img className="card-img-top" src={gule} alt="Gule" />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">Rubber balls</h5>
                  <p className="m-b-0 font-14">
                    Different sizes, colours, hardnesses, with and without metal
                    core
                  </p>
                </CardBody>
              </Card>
              <Modal
                size="xl"
                isOpen={modal2}
                toggle={toggle2.bind(null)}
                className={props.className}
              >
                <ModalHeader toggle={toggle2.bind(null)}>
                  Rubber balls
                </ModalHeader>
                <ModalBody>
                  <img
                    src={guleLg}
                    style={{
                      float: "left",
                      margin: "5px",
                      maxWidth: "600px",
                      width: "100%",
                    }}
                  />
                  <p>
                    <h5>Popis:</h5>
                    All-rubber ball made of a special rubber compound with a
                    favourable ratio of rebound and damping. <br />
                    The balls are placed together with the sieved material
                    directly on the sieve of the shaker, providing cleaning and
                    higher efficiency when bouncing and re-impacting on the
                    sieve.
                    <br />
                    <br />
                    Rubber balls can be used as a sealing element in pneumatic
                    and hydraulic equipment (ball valves, check valves,...)
                    <br />
                    <br />
                    <h5>Nominal dimensions of supplied balls:</h5>
                    diameter d = 25 mm <br />
                    diameter d = 30 mm <br />
                    diameter d = 35 mm <br />
                    diameter d = 43 mm <br />
                    diameter d = 50 mm <br />
                    diameter d = 71 mm <br />
                    other dimensions on request <br />
                    <br />
                    <h5>Material:</h5>
                    For sands and other building materials, special MK53 series
                    rubber compound with high reflectivity guaranteed down to
                    -30°C and increased abrasion resistance, <br />
                    8741 material is a rubber compound based on natural rubber.
                    Balls made of it are characterized by high reflectivity with
                    very good cushioning properties on impact. <br />
                    For the food industry, balls made of food-grade or silicone
                    rubber are produced <br />
                    <br />
                    <h5>Application:</h5>Construction industry <br />V Food
                    industry <br />
                    <br />
                    <h5>Longevity and efficiency:</h5> verified by operational
                    tests
                  </p>
                </ModalBody>
              </Modal>
            </Col>
            <Col md="4">
              <Card className="card-shadow">
                <div className="img-ho">
                  <img
                    className="card-img-top"
                    src={okruzky}
                    alt="wrappixel kit"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">O rings</h5>
                  <p className="m-b-0 font-14">
                    Different sizes according to customer requirements.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-shadow">
                <div className="img-ho">
                  <img
                    className="card-img-top"
                    src={tesnenia}
                    alt="wrappixel kit"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">Gaskets</h5>
                  <p className="m-b-0 font-14">
                    Different sealing from very small sizes to large ones
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-shadow">
                <div className="img-ho">
                  <img
                    className="card-img-top"
                    src={prisavky1}
                    alt="wrappixel kit"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">
                    Suction cups of larger dimensions
                  </h5>
                  <p className="m-b-0 font-14">
                    It is available in different colours and rubber properties.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-shadow">
                <div className="img-ho">
                  <img
                    className="card-img-top"
                    src={prisavky2}
                    alt="wrappixel kit"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">
                    Suction cups of smaller dimensions
                  </h5>
                  <p className="m-b-0 font-14">
                    It is available in different colours and rubber properties.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PortfolioComponent;
