import React from "react";
import { Row, Container } from "reactstrap";

const GumaEN = () => {
  return (
    <Container>
      <Row className="m-t-10 ">
        {" "}
        <h2>Custom manufacturing of rubber products</h2>
      </Row>
      <Row>
        <p>
          In cooperation with our foreign partner, we offer the design and
          supply of specialised rubber solutions and products. Our solutions
          focus on food grade rubber products and rubber certified for use in
          gas industry and other equipment. <br />
          We can produce different shapes and sizes of seals, stamps and other
          products according to customer's specification and technical
          documentation. Be inspired by examples of our successful products in
          the section <a href="#/EN/products">Products</a>
        </p>
      </Row>
    </Container>
  );
};

export default GumaEN;
