/* eslint-disable */
import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import {
  Container,
  NavbarBrand,
  Navbar,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse,
} from "reactstrap";
import ReactCountryFlag from "react-country-flag";

import logo from "../../assets/images/logo/cele logo.gif";
import { NavLink } from "react-router-dom";

const HeaderEN = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/

  return (
    <div className="topbar bg-white" id="top">
      <div className="header6">
        <Container className="po-relative">
          <Navbar className="navbar-expand-lg h6-nav-bar h2-nav">
            <NavbarBrand href="#/">
              <img src={logo} alt="samat" height="40 px" />{" "}
            </NavbarBrand>

            <Collapse
              isOpen={isOpen}
              navbar
              className="hover-dropdown font-14 justify-content-end"
              id="h6-info"
            >
              <Nav tabs className="ms-auto">
                <NavItem>
                  <NavLink className="nav-link" to={"/EN/Services"}>
                    Services
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to={"/EN/products"}>
                    Products
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink active className="nav-link" to={"/EN/about"}>
                    About
                  </NavLink>
                </NavItem>
                {/*
                <NavItem>
                  <NavLink className="nav-link" to={"/kontakt"}>
                    Kontakt
                  </NavLink>
  </NavItem> */}
              </Nav>
              <a href="/#/">
                <ReactCountryFlag
                  countryCode="SK"
                  svg
                  style={{
                    width: "2em",
                    height: "2em",
                    marginLeft: "20px",
                  }}
                  title="SK"
                />
              </a>
            </Collapse>
          </Navbar>
        </Container>
      </div>
    </div>
  );
};
export default HeaderEN;
