/* eslint-disable */
import React from "react";
import { Container, Row, Col } from "reactstrap";
import Obfuscate from "react-obfuscate";

const FooterEN = () => {
  return (
    <div className="footer4 b-t spacer">
      <Container>
        <Row>
          <Col lg="3" md="6" className="m-b-30">
            <h5 className="m-b-20">Address</h5>
            <p>
              Lucna 3029/2
              <br /> 916 01 Stara Tura
              <br /> Slovak republic
            </p>
          </Col>
          <Col lg="3" md="6" className="m-b-30">
            <h5 className="m-b-20">Plucking fingers</h5>
            <p>Ing. Jana Durisova</p>
            <p>
              Email :{" "}
              <Obfuscate
                style={{ display: "inline-block" }}
                email="durisova@samattrade.sk"
              />
            </p>
            <p>
              Mobile :{" "}
              <Obfuscate
                style={{ display: "inline-block" }}
                tel="+421 905 760 646"
              />
            </p>
          </Col>
          <Col lg="3" md="6" className="m-b-30">
            <h5 className="m-b-20">Automation and other topics</h5>
            <p>Dipl. Ing. Martin Duris</p>
            <p>
              Email :{" "}
              <Obfuscate
                style={{ display: "inline-block" }}
                email="mduris@samattrade.sk"
              />{" "}
            </p>
            <p>
              Mobile :{" "}
              <Obfuscate
                style={{ display: "inline-block" }}
                tel="+421 908 882 816"
              />
            </p>
          </Col>

          <Col lg="3" md="6" className="m-b-30">
            <h5 className="m-b-20">Map</h5>

            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d552.8132900793125!2d17.692818032576763!3d48.76971534760507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4713511fbe6cbfa5%3A0x3c2c63564ac56222!2sSamat%20trade%20s.r.o.!5e0!3m2!1sen!2ssk!4v1689524853032!5m2!1sen!2ssk"></iframe>
          </Col>
        </Row>
        <div className="f4-bottom-bar">
          <Row>
            <Col md="12">
              <div className="d-flex font-14 justify-content-between">
                <div className="m-t-10 m-b-10 copyright">
                  Copyright Samat trade, s.r.o.
                </div>
                <div className="links ms-auto m-t-10 m-b-10">
                  <a
                    href="https://samattrade.sk/Zasady_ochrany_osobnych_udajov_spolocnosti.pdf"
                    className="p-10"
                  >
                    Privacy policy
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default FooterEN;
