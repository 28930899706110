import React from "react";

const UkazkyPrstov = () => {
  return (
    <div className="m-t-10">
      <h3>Ukážky domáceho použitia šklabacích prstov</h3>
      <h6>Ukážka výroby domácej šklbačky</h6>
      <iframe
        width="100%"
        height="300"
        src="https://www.youtube.com/embed/Ff8Y76MZCUM"
        title="How to Make Chicken Plucker / Feather Cleaning Machine / Full Video"
        allow="web-share"
        allowFullscreen
      ></iframe>
      <h6>Šklbačka s použitím vŕtačky</h6>
      <iframe
        width="100%"
        height="300"
        src="https://www.youtube.com/embed/J2LjMdJPbHo"
        title="DIY cordless Chicken plucker in action"
        allow="web-share"
        allowFullscreen
      ></iframe>
      <h6>Ukážka rýchlosti ošklbania sliepky</h6>
      <iframe
        width="100%"
        height="300"
        src="https://www.youtube.com/embed/DMydVdwmuws"
        title="How to pluck a chicken in 14 seconds - Homemade Whizbang Chicken Plucker"
        allow="web-share"
        allowFullscreen
      ></iframe>
    </div>
  );
};

export default UkazkyPrstov;
