import React, { useState, useRef, useEffect } from "react";

// core components
import Header from "../../components/header/header.jsx";
import Footer from "../../components/footer/footer.jsx";

// sections for this page
import Formular from "./formular.jsx";
import OPrstoch from "./oprstoch.jsx";
import UkazkyPrstov from "./ukazkyPrstov.jsx";
import CenyPrstov from "./cenyPrstov.jsx";
import Dakujeme from "./dakujeme.jsx";

import { Container, Row, Col } from "reactstrap";

const setScrollPosition = (element) => {
  console.log(element);
  window.scrollTo({
    top: element.current.offsetTop,
    behavior: "smooth",
  });
};

const Prsty = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [mailSent, setMailSent] = useState(false);
  //const elemHeader = useRef();
  const elemForm = useRef();

  const handleSent = (state) => {
    setMailSent(state);
    setScrollPosition(elemForm);
  };

  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="spacer">
          <Container>
            <Row>
              <Col xs="6">
                <OPrstoch />
              </Col>
              <Col xs="auto">
                <UkazkyPrstov />
              </Col>
            </Row>
          </Container>
          <div className="bg-light" ref={elemForm}>
            <Container>
              <Row>
                <Col xs="6">
                  {mailSent && <Dakujeme />}
                  {!mailSent && <Formular handleSent={handleSent} />}
                </Col>
                <Col xs="6">
                  <CenyPrstov />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Prsty;
