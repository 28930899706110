import React from "react";
import { Row, Col, Container } from "reactstrap";

import machine from "../../../assets/images/sluzby/twincat02.png";
import codesys from "../../../assets/images/sluzby/CoDeSys_Logo.jpg";
import teco from "../../../assets/images/sluzby/LG10-TECO-Logo.jpg";
import kusta from "../../../assets/images/sluzby/kusta.png";

const Automatizacia = () => {
  return (
    <div className="bg-light p-t-10 p-b-20 m-t-10">
      <Container>
        <Row className="m-t-10">
          {" "}
          <h2>Automatizácia výrobných zariadení</h2>
        </Row>
        <Row>
          <p>
            Do nášho portfólia spadá projektovanie a programovanie riadiacich
            systémov na základe výrobných špecifikácií. <br />
            Máme bohaté skúsenosti s programovaním dopravníkových systémov a aj
            rôznych manuálnych, poloautomatických a plne automatických staníc a
            liniek.
          </p>
          <p>
            V oblasti automatizačných technológií a robotiky ponúkame tieto
            služby:
          </p>
        </Row>
        <Row className="m-t-20">
          <Col sm="2">
            <img src={machine} className="img-circle maxh-100" alt="twincat3" />
          </Col>
          <Col md="7" className="d-flex align-items-center">
            <p>
              Projektovanie a programovanie riadiacich systémov od nemeckej
              spoločnosti <b>Beckhoff</b> v prostredí <b>TwinCAT 3</b> na
              základe požiadaviek od zákazníka. (objektové programovanie)
              <br />
              Takisto vieme poskytnúť programovanie v{" "}
              <b>TwinCAT HMI (TF2000)</b> - HTML/JavaScript.
            </p>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col sm="2">
            <img src={codesys} className="img-circle maxh-100" alt="codesys" />
          </Col>
          <Col md="7" className="d-flex align-items-center">
            <p>
              Programovanie riadiacich systémov v prostredí CoDeSys. (objektové
              programovanie)
            </p>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col sm="2">
            <img src={teco} className="img-circle maxh-100" alt="tecomat" />
          </Col>
          <Col md="7" className="d-flex align-items-center">
            <p>
              Programovanie riadiacich systémov of českej firmy TECO.
              <br />
              Protfólio tejto firmy je zamerané prevažne na automatizáciu budov.
            </p>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col sm="2"></Col>
          <Col md="7" className="d-flex align-items-center">
            <p>
              Sprevádzanie a stanice/linky od vývoja až po odovzdanie konečnému
              zákazníkovi a následný support.
            </p>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col sm="2">
            <img
              src={kusta}
              className="img-circle maxh-100"
              alt="kuka-staubli"
            />
          </Col>
          <Col md="7" className="d-flex align-items-center">
            <p>
              Programovanie robotov značky KUKA a Stäubli cez mxAutomation
              (KUKA) a uniVAL plc (Stäubli) v prostredí TwinCAT 3 alebo CoDeSys.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Automatizacia;
