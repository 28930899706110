import React from "react";
import "./assets/scss/style.scss";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserHistory } from "history";
import { Route, Routes, HashRouter } from "react-router-dom";

import Onas from "./views/o_nas/onas";
import Produkty from "./views/produkty/produkty";
import Prsty from "./views/prsty/prsty";
import Sluzby from "./views/sluzby/sluzby";

import OnasEN from "./views/o_nas/onasEN";
import ProduktyEN from "./views/produkty/produktyEN";
import SluzbyEN from "./views/sluzby/sluzbyEN";

import Page404 from "./views/pageNotFound/page404";

const root = ReactDOM.createRoot(document.getElementById("root"));

var hist = createBrowserHistory();
root.render(
  <HashRouter history={hist}>
    <Routes>
      {/* <Route path="/kontakt" element={<Kontakt />} /> */}
      <Route path="/onas" element={<Onas />} />
      <Route path="/produkty" element={<Produkty />} />
      <Route path="/prsty" element={<Prsty />} />
      <Route path="/" element={<Sluzby />} />
      <Route path="/sluzby" element={<Sluzby />} />

      <Route path="/EN/about" element={<OnasEN />} />
      <Route path="/EN/products" element={<ProduktyEN />} />
      <Route path="/EN/services" element={<SluzbyEN />} />

      <Route path="*" element={<Page404 />} />
    </Routes>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
