import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/headerEN.jsx";
import Footer from "../../components/footer/footerEN.jsx";

// sections for this page
import Automatizacia from "./sections/automatizaciaEN.jsx";
import Guma from "./sections/gumaEN.jsx";
import Carousel01 from "../o_nas/sections/Carousel01.jsx";

// imgs

const SluzbyEN = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid"></div>
        <div className="spacer">
          <Guma />
          <Carousel01 />
          <Automatizacia />
        </div>
      </div>
      <Footer />
    </div>
  );
};

SluzbyEN.propTypes = {
  classes: PropTypes.object,
};

export default SluzbyEN;
