import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/headerEN.jsx";
import Footer from "../../components/footer/footerEN.jsx";

// sections for this page
import Carousel01 from "./sections/Carousel01.jsx";
import { Container } from "reactstrap";

const OnasEN = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="spacer">
            <Carousel01 />
            <Container className="m-t-10">
              <p>
                The company has been operating successfully since 2006. Daily we
                strive for an individual approach and finding the best solution
                for each of our customers. Our goal is to make our rubber
                products to meet all your requirements and help you achieve best
                possible performance for your equipment.{" "}
              </p>
              <p>
                In cooperation with our foreign partner, we offer construction
                and supply of specialized rubber solutions and products. Our
                solutions focus on food grade rubber and rubber products
                certified for use in gas and other applications.
              </p>
            </Container>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

OnasEN.propTypes = {
  classes: PropTypes.object,
};

export default OnasEN;
