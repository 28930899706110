import React from "react";
import { UncontrolledCarousel } from "reactstrap";
import img01 from "../../../assets/images/carousel/okruzky_wide.jpg";
import img02 from "../../../assets/images/carousel/prisavky01_wide.jpg";
import img03 from "../../../assets/images/carousel/prisavky_wide.jpg";
import img04 from "../../../assets/images/carousel/produkty01.jpg";

const items = [
  {
    src: img04,
    altText: "Prehľad produktov",
    caption: "",
    header: "",
  },
  {
    src: img01,
    altText: "O krúžky",
    caption: "Rôzne veľkosti podľa požiadaviek zákazníka",
    header: "O krúžky",
  },
  {
    src: img02,
    altText: "Prísavky",
    caption: "Rôzne prevedienia podľa požiadaviek zákazníka",
    header: "Prísavky",
  },
  {
    src: img03,
    altText: "Prísavky",
    caption: "Rôzne prevedienia podľa požiadaviek zákazníka",
    header: "Prísavky",
  },
];

const Carousel01 = () => (
  <div>
    <UncontrolledCarousel items={items} />
  </div>
);
export default Carousel01;
