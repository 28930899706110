import React, { useState, useEffect } from "react";

import { Spinner } from "reactstrap";

const CenyPrstov = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchJson = () => {
    setIsLoading(true);
    fetch("./ceny.json")
      .then((response) => {
        //console.log(response);
        return response.json();
      })
      .then((data) => {
        // console.log(data);
        setData(data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  useEffect(() => {
    /*
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://jsonplaceholder.typicode.com/users"
        );
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } */
    fetchJson();
  }, []);
  if (isLoading)
    return (
      <Spinner animation="border" role="status">
        Loading...
      </Spinner>
    );
  if (!isLoading)
    return (
      <div className="m-t-20">
        <h3>Ceny</h3>
        <div className={isLoading && "invisible"}>
          <p>(Ceny sa môžu vplyvom aktuálnej situácie na trhu meniť.)</p>
          <h6>Prst 55°Sh</h6>
          <p>{`${data.cena55.toFixed(2)}€ bez DPH (${(
            data.cena55 * 1.2
          ).toFixed(3)}€ s DPH) / kus`}</p>
          <h6>Prst 35°Sh</h6>
          <p>{`${data.cena30.toFixed(2)}€ bez DPH (${(
            data.cena30 * 1.2
          ).toFixed(3)}€ s DPH) / kus`}</p>
          <p>Pre vačšie objednávky použite kontakt na Ing. Janu Ďurišovú.</p>
        </div>
        <h5>Doprava:</h5>
        <h6>Slovenská pošta express kuriér:</h6>
        <p>
          (10ks až 50ks) do 2kg bez DPH {data.postovne.SPdo2.toFixed(2)}€ (s DPH{" "}
          {(data.postovne.SPdo2 * 1.2).toFixed(2)}€)
        </p>
        <p>
          (51ks až 100ks) do 5kg na poštu bez DPH{" "}
          {data.postovne.SPdo5np.toFixed(2)}€ (s DPH{" "}
          {(data.postovne.SPdo5np * 1.2).toFixed(2)}€)
        </p>
        <p>
          (51ks až 100ks) do 5kg bez DPH {data.postovne.SPdo5.toFixed(2)}€ (s
          DPH {(data.postovne.SPdo5 * 1.2).toFixed(2)}€)
        </p>
        <p>
          (101ks až 200ks) do 10kg bez DPH {data.postovne.SPdo10.toFixed(2)}€ (s
          DPH {(data.postovne.SPdo10 * 1.2).toFixed(2)}€)
        </p>
        <p>
          (201ks až 450ks) do 15kg bez DPH {data.postovne.SPdo15.toFixed(2)}€ (s
          DPH {(data.postovne.SPdo15 * 1.2).toFixed(2)}€)
        </p>

        <h6>Packeta (zásielkovňa)</h6>
        <p>Cena na vyžiadanie</p>
      </div>
    );
};

export default CenyPrstov;
