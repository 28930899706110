import React from "react";
import { Row, Col, Container } from "reactstrap";

import machine from "../../../assets/images/sluzby/twincat02.png";
import codesys from "../../../assets/images/sluzby/CoDeSys_Logo.jpg";
import teco from "../../../assets/images/sluzby/LG10-TECO-Logo.jpg";
import kusta from "../../../assets/images/sluzby/kusta.png";

const AutomatizaciaEN = () => {
  return (
    <div className="bg-light p-t-10 p-b-20 m-t-10">
      <Container>
        <Row className="m-t-10">
          {" "}
          <h2>Automation of production equipment</h2>
        </Row>
        <Row>
          <p>
            Our portfolio includes the design and programming of control systems
            based on production specifications. <br />
            We have extensive experience in programming conveyor systems as well
            as various manual, semi-automatic and fully automatic stations and
            lines.
          </p>
          <p>
            We offer the following services in the field of automation
            technology and robotics:
          </p>
        </Row>
        <Row className="m-t-20">
          <Col sm="2">
            <img src={machine} className="img-circle maxh-100" alt="twincat3" />
          </Col>
          <Col md="7" className="d-flex align-items-center">
            <p>
              Design and programming of control systems from a German company{" "}
              <b>Beckhoff</b> in <b>TwinCAT 3</b> environment based on customer
              specifications (OOP)
              <br />
              We can also provide programming in <b>TwinCAT HMI (TF2000)</b> -
              HTML/JavaScript.
            </p>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col sm="2">
            <img src={codesys} className="img-circle maxh-100" alt="codesys" />
          </Col>
          <Col md="7" className="d-flex align-items-center">
            <p>Programming of control systems in CoDeSys environment. (OOP)</p>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col sm="2">
            <img src={teco} className="img-circle maxh-100" alt="tecomat" />
          </Col>
          <Col md="7" className="d-flex align-items-center">
            <p>
              Programming of control systems of the Czech company TECO.
              <br />
              The protfolio of this company is mainly focused on building
              automation.
            </p>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col sm="2"></Col>
          <Col md="7" className="d-flex align-items-center">
            <p>
              Accompaniment and stations/lines from development to handover to
              the end customer and subsequent support.
            </p>
          </Col>
        </Row>
        <Row className="m-t-20">
          <Col sm="2">
            <img
              src={kusta}
              className="img-circle maxh-100"
              alt="kuka-staubli"
            />
          </Col>
          <Col md="7" className="d-flex align-items-center">
            <p>
              Programming of KUKA and Stäubli robots via mxAutomation (KUKA) and
              uniVAL plc (Stäubli) in TwinCAT 3 or CoDeSys.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AutomatizaciaEN;
