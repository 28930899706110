import React, { useEffect } from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import Footer from "../../components/footer/footer.jsx";

// sections for this page
import Carousel01 from "./sections/Carousel01.jsx";
import { Container } from "reactstrap";

const Onas = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="spacer">
            <Carousel01 />
            <Container className="m-t-10">
              <h3>O Firme</h3>
              <p>
                Firma úspešne funguje od svojho vzniku v roku 2006. Denne sa
                snažíme o individuálny prístup a hľadanie najlepšieho riešenia
                pre každého z Vás. Našim cieľom je, aby prednosti našich
                výrobkov z gumy a ďalších výrobkov našej špecializácie pomohli k
                bezchybnému fungovaniu vašich strojov a zariadení.{" "}
              </p>
              <h3>Gumárenská výroba</h3>
              <p>
                V spolupráci s našim zahraničným partnerom ponúkame konštrukciu
                a dodávky špecializovaných riešení a produktov z gumy. Naše
                riešenia sa zameriavajú na výrobky z potravinárskej gumy a gumy
                s certifikátom na použitie v plynárenských a iných zariadeniach.
              </p>
              <h3>Priemyselná automatizácia</h3>
              <p>
                V oblasti priemyselnej automatizácii vieme poskytnúť návrh a
                vývoj riadenia pre rôzne veľkosti strojov. Máme dlhoročné
                skúsenosti s vývojom veľkých liniek aj malých ručných pracovísk
                a dopravnkovými systémami medzi jednotlivými stanicami.
              </p>
            </Container>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Onas.propTypes = {
  classes: PropTypes.object,
};

export default Onas;
