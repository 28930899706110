import React from "react";

const Dakujeme = () => {
  return (
    <div className="m-t-10">
      <h3>Ďakujeme za objednávku!</h3>
      <h5>
        Ak vám nepríde email s potvrdením objednávky, napíšte nám na niektorú z
        našich adries.
      </h5>
    </div>
  );
};

export default Dakujeme;
