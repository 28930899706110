import React from "react";

import prst_koty from "../../assets/images/portfolio/prst_koty_wm01.jpg";

const OPrstoch = () => {
  return (
    <div className="m-t-10">
      <h3>Šklabacie prsty</h3>
      <img
        src={prst_koty}
        style={{ float: "left", margin: "5px", width: "100%" }}
        alt="Sklabaci prst"
      />
      <h5>Popis:</h5>
      <p>
        Šklbací prst je vyrobený z potravinárskej gumy a je určený na strojné
        odstraňovanie peria z hydiny. <br />
        Šklbacie prsty s tvrdosťou 55°Sh doporučujeme na šklbanie sliepok, ale
        výborné výsledky dosiahnete i pri šklbaní moriek. <br />
        Pre šklbanie menších operencov (napr. prepelíc) doporučujeme mäkšie
        šklbacie prsty s tvrdosťou 35°Sh. <br />
        Vieme zabezpečiť aj iné tvrdosti podľa požiadaviek zákazníka.
      </p>
      <h5>Menovité rozmery a technické údaje:</h5>
      <p>
        priemer d = 20 mm
        <br /> dĺžka l = 90 mm
        <br /> tvrdosť = 55°Sh a 35°Sh
      </p>
      <h5>Materiál:</h5>
      <p>potravinárska guma</p>
      <h5>Použitie:</h5>
      <p>v hydinárskom priemysle, v potravinárskom priemysle</p>
      <h5>Uchytenie:</h5>
      <p>
        Napr. hliníkový odliatok diera d = 18,50 mm (primer musí byť menší ako
        menovitý priemer prsta), hrúbka v mieste uchytenia cca 2 mm <br />
        <br />
        Funkčnosť overená prevádzkovými skúškami v slovenských hydinárskych
        závodoch
      </p>
    </div>
  );
};

export default OPrstoch;
