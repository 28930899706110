import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/headerEN.jsx";
import Footer from "../../components/footer/footerEN.jsx";

// sections for this page
import PortfolioComponentEN from "./sections/portfoliocomponentEN.jsx";

const ProduktyEN = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid"></div>
        <PortfolioComponentEN />
      </div>
      <Footer />
    </div>
  );
};

ProduktyEN.propTypes = {
  classes: PropTypes.object,
};

export default ProduktyEN;
