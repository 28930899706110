import React from "react";
import { Row, Container } from "reactstrap";

const Guma = () => {
  return (
    <Container>
      <Row className="m-t-10 ">
        {" "}
        <h2>Zákazková výroba gumárenských produktov</h2>
      </Row>
      <Row>
        <p>
          V spolupráci s našim zahraničným partnerom ponúkame konštrukciu a
          dodávky špecializovaných riešení a produktov z gumy. Naše riešenia sa
          zameriavajú na výrobky z potravinárskej gumy a gumy s certifikátom na
          použitie v plynárenských a iných zariadeniach. <br />
          Vieme vyrobiť rôzne tvary a veľkosti tesnení, pečiatok a ďalších
          produktov podľa špecifikácie a technickej dokumentácie zákazníka.
          Inšpirujte sa príkladom našich úspešných produktov v sekcii{" "}
          <a href="#/produkty">Produkty</a>
        </p>
      </Row>
    </Container>
  );
};

export default Guma;
