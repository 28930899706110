import React from "react";
import PropTypes from "prop-types";

// core components
import Header from "../../components/header/header.jsx";
import Footer from "../../components/footer/footer.jsx";

import pic404 from "./404.svg";
import { Container, Row, Card, CardImg, Alert } from "reactstrap";

// sections for this page

const Page404 = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid ">
          <div className="mini-spacer"></div>
          <Container>
            <Row>
              <Card style={{ flex: 1 }}>
                <CardImg src={pic404} className="maxh-400"></CardImg>

                <Alert color="danger">Stránka sa nenašla!</Alert>
              </Card>
            </Row>
          </Container>
          <div className="mini-spacer"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Page404.propTypes = {
  classes: PropTypes.object,
};

export default Page404;
