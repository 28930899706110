/* eslint-disable */
import { useState } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Collapse,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap";

import gule from "../../../assets/images/portfolio/gule01_md.jpg";
import prsty from "../../../assets/images/portfolio/prst.jpg";
import prst_koty from "../../../assets/images/portfolio/prst_koty.jpg";
import prisavky1 from "../../../assets/images/portfolio/prisavky01_md.jpg";
import okruzky from "../../../assets/images/portfolio/okruzky_md.jpg";
import tesnenia from "../../../assets/images/portfolio/tesnenia_md.jpg";
import prisavky2 from "../../../assets/images/portfolio/prisavky_md.jpg";

import guleLg from "../../../assets/images/portfolio/gule01_lg.jpg";
import prisavky1Lg from "../../../assets/images/portfolio/prisavky01_lg.jpg";
import okruzkyLg from "../../../assets/images/portfolio/okruzky_lg.jpg";
import tesneniaLg from "../../../assets/images/portfolio/tesnenia_lg.jpg";
import prisavky2Lg from "../../../assets/images/portfolio/prisavky_lg.jpg";

const PortfolioComponent = (props) => {
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [modal5, setModal5] = useState(false);
  const [modal6, setModal6] = useState(false);

  const toggle1 = () => {
    setModal1(!modal1);
  };
  const toggle2 = () => {
    setModal2(!modal2);
  };
  const toggle3 = () => {
    setModal3(!modal3);
  };
  const toggle4 = () => {
    setModal4(!modal4);
  };
  const toggle5 = () => {
    setModal5(!modal5);
  };
  const toggle6 = () => {
    setModal6(!modal6);
  };

  const [isOpenPrsty, setIsOpenPrsty] = useState(false);

  const togglePrsty = () => setIsOpenPrsty(!isOpenPrsty);

  return (
    <div>
      <div className="spacer">
        <Container>
          <Row className="justify-content-center">
            <Col md="7" className="text-center">
              <h2 className="title">Ukážky našich produktov z gumy</h2>
            </Col>
          </Row>
          <Row className="m-t-40">
            <Col md="4" onClick={togglePrsty}>
              <Card className="card-shadow ">
                <div className="img-ho" onClick={toggle1.bind(null)}>
                  <img
                    className="card-img-top maxh-400"
                    src={prsty}
                    alt="sklbacie prsty"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">Šklbacie prsty</h5>
                  <p className="m-b-0 font-14">Určené na čistenie hydiny</p>
                </CardBody>
              </Card>
              <Modal
                size="xl"
                isOpen={modal1}
                toggle={toggle1.bind(null)}
                className={props.className}
              >
                <ModalHeader toggle={toggle1.bind(null)}>
                  Šklbacie prsty
                </ModalHeader>
                <ModalBody>
                  <img src={prsty} style={{ float: "left", margin: "40px" }} />
                  <h5>Popis:</h5>
                  <p>
                    Šklbací prst je vyrobený z potravinárskej gumy a je určený
                    na strojné odstraňovanie peria z hydiny. <br />
                    Šklbacie prsty s tvrdosťou 55°Sh doporučujeme na šklbanie
                    sliepok, ale výborné výsledky dosiahnete i pri šklbaní
                    moriek. <br />
                    Pre šklbanie menších operencov (napr. prepelíc) doporučujeme
                    mäkšie šklbacie prsty s tvrdosťou 30°Sh. <br />
                    Vieme zabezpečiť aj iné tvrdosti podľa požiadaviek
                    zákazníka.
                  </p>
                  <h5>Menovité rozmery a technické údaje:</h5>
                  <p>
                    priemer d = 20 mm
                    <br /> dĺžka l = 90 mm
                    <br /> tvrdosť = 55°Sh a 30°Sh
                  </p>
                  <h5>Materiál:</h5>
                  <p>potravinárska guma</p>
                  <h5>Použitie:</h5>
                  <p>v hydinárskom priemysle, v potravinárskom priemysle</p>
                  <h5>Uchytenie:</h5>
                  <p>
                    Napr. hliníkový odliatok diera d = 18,50 mm (primer musí byť
                    menší ako menovitý priemer prsta), hrúbka v mieste uchytenia
                    cca 2 mm <br />
                    <br />
                    Funkčnosť overená prevádzkovými skúškami v slovenských
                    hydinárskych závodoch
                  </p>
                  <img
                    src={prst_koty}
                    style={{ float: "left", margin: "5px", width: "100%" }}
                  />
                </ModalBody>
              </Modal>
            </Col>
            <Col md="4">
              <Card className="card-shadow">
                <div className="img-ho" onClick={toggle2.bind(null)}>
                  <img
                    className="card-img-top maxh-400"
                    src={gule}
                    alt="Gule"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">Gumové gule</h5>
                  <p className="m-b-0 font-14">
                    Rôzne rozmery, farby, tvrdosti, s kovovým jadrom aj bez
                  </p>
                </CardBody>
              </Card>
              <Modal
                size="xl"
                isOpen={modal2}
                toggle={toggle2.bind(null)}
                className={props.className}
              >
                <ModalHeader toggle={toggle2.bind(null)}>
                  Gumové gule
                </ModalHeader>
                <ModalBody>
                  <img
                    src={guleLg}
                    style={{
                      float: "left",
                      margin: "30px",
                      marginLeft: "15px",
                      maxWidth: "600px",
                      width: "100%",
                    }}
                  />
                  <p>
                    <h5>Popis:</h5>
                    Celogumová guľa zo špeciálnej gumovej zmesi s priaznivým
                    pomerom odrazu a tlmenia. <br />
                    Gule sa umiestňujú spolu s triedeným materiálom priamo na
                    sito vytriasacieho stroja, pričom pri odraze a opätovnom
                    dopade na sito zabezpečujú jeho čistenie a vyššiu účinnosť.
                    <br />
                    <br />
                    Gumové gule je možné použiť ako tesniaci prvok v
                    pneumatických a hydraulických zariadeniach (guľové ventily,
                    spätné klapky,...)
                    <br />
                    <br />
                    <h5>Menovité rozmery dodávaných gúľ:</h5>
                    priemer d = 25 mm <br />
                    priemer d = 30 mm <br />
                    priemer d = 35 mm <br />
                    priemer d = 43 mm <br />
                    priemer d = 50 mm <br />
                    priemer d = 71 mm <br />
                    iné rozmery na požiadanie <br />
                    <br />
                    <h5>Materiál:</h5>
                    Pre piesky a iné stavebné materiály špeciálna gumová zmes
                    radu MK53 s vysokou odrazivosťou zaručenou až do -30°C a so
                    zvýšenou odolnosťou voči oderu, <br />
                    materiál 8741 je gumová zmes na báze prírodného kaučuku.
                    Gule z neho vyrobené sa vyznačujú vysokou odrazivosťou s
                    veľmi dobrými tlmiacimi vlastnosťami pri dopade. <br />
                    Pre potravinársky priemysel sa vyrábajú gule z
                    potravinárskej alebo silikónovej gumy <br />
                    <br />
                    <h5>Použitie:</h5>V stavebnom priemysle <br />V
                    potravinárskom priemysle <br />
                    <br />
                    <h5>Životnosť a účinnosť:</h5> overená prevádzkovými
                    skúškami
                  </p>
                </ModalBody>
              </Modal>
            </Col>
            <Col md="4">
              <Card className="card-shadow">
                <div className="img-ho">
                  <img
                    className="card-img-top maxh-400"
                    src={okruzky}
                    alt="O kruzky"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">O krúžky</h5>
                  <p className="m-b-0 font-14">
                    Rôzne rozmery podľa požiadaviek zákazníka.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-shadow">
                <div className="img-ho">
                  <img
                    className="card-img-top maxh-400"
                    src={tesnenia}
                    alt="tesnenia"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">Tesnenia</h5>
                  <p className="m-b-0 font-14">
                    Rôzne tesnenie od velmi malých rozmerov až po veľké
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-shadow">
                <div className="img-ho">
                  <img
                    className="card-img-top maxh-400"
                    src={prisavky1}
                    alt="prisavky"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">
                    Prísavky väčších rozmerov
                  </h5>
                  <p className="m-b-0 font-14">
                    Prevedienie je možné v rozchy farbách a vlastnostiach gumy.
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-shadow">
                <div className="img-ho">
                  <img
                    className="card-img-top maxh-400"
                    src={prisavky2}
                    alt="prisavky"
                  />
                </div>
                <CardBody>
                  <h5 className="font-medium m-b-0">
                    Prísavky menších rozmerov
                  </h5>
                  <p className="m-b-0 font-14">
                    Prevedienie je možné v rozchy farbách a vlastnostiach gumy.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PortfolioComponent;
