/* eslint-disable */
import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import {
  Container,
  NavbarBrand,
  Navbar,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse,
} from "reactstrap";
import ReactCountryFlag from "react-country-flag";

import logo from "../../assets/images/logo/cele logo.gif";
import logo3 from "../../assets/images/logo/logo fa.svg";
import logo2 from "../../assets/images/logo/logo2.gif";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/

  return (
    <div className="topbar bg-antra" id="top">
      <div className="header6">
        <Container className="po-relative">
          <Navbar className="navbar-expand-lg h6-nav-bar h2-nav">
            <NavbarBrand href="#/">
              <img src={logo3} alt="samat" height="70 px" />{" "}
            </NavbarBrand>

            <Collapse
              isOpen={isOpen}
              navbar
              className="hover-dropdown font-14 justify-content-end"
              id="h6-info"
            >
              <Nav pills className="ms-auto">
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to={"/"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Služby
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to={"/produkty"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Produkty
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active
                    className="nav-link"
                    to={"/onas"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    O nás
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active
                    className="nav-link"
                    to={"/prsty"}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Šklbacie prsty
                  </NavLink>
                </NavItem>
                {/*
                
                <NavItem>
                  <NavLink className="nav-link" to={"/kontakt"}>
                    Kontakt
                  </NavLink>
  </NavItem> */}
              </Nav>
              {/*
              <a href="/#/EN/services">
                <ReactCountryFlag
                  countryCode="US"
                  svg
                  style={{
                    width: "2em",
                    height: "2em",
                    marginLeft: "20px",
                  }}
                  title="US"
                />
                </a>*/}
            </Collapse>
          </Navbar>
        </Container>
      </div>
    </div>
  );
};
export default Header;
